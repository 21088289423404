import AOS from 'aos'
import 'aos/dist/aos.css'

window.onNuxtReady(() => {
  AOS.refresh()
})

export default ({ app }) => {
  /* eslint-disable */
  setTimeout(() => {
    app.AOS = new AOS.init({ once: true, duration: 700 })
  }, 1)
  /* eslint-enable */
}
