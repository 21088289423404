<template>
  <div class="error-page dark-background">
    <div class="title-container">
      <h1 v-if="error.statusCode === 404" class="title">404 Page not found</h1>
      <h1 v-else class="title">An error occurred</h1>
      <AppButton :to="getCurrentLanguage === 'fr' ? HOMEPAGE.fr_PATH : HOMEPAGE.en_PATH"
        >Back to homepage</AppButton
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AppButton from '@/components/common/AppButton'
import { ROUTES } from '@/utils/routes'

export default {
  name: 'ErrorLayout',
  components: { AppButton },
  scrollToTop: true,
  props: {
    error: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      HOMEPAGE: ROUTES.HOMEPAGE,
    }
  },
  computed: {
    ...mapGetters(['getCurrentLanguage']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/pages/ErrorPage';
</style>
