<template>
  <component
    :is="to ? 'div' : 'button'"
    :class="`button ${className}`"
    :data-aos="disableAos ? '' : 'fade-in'"
    data-aos-delay="100"
    data-aos-duration="550"
  >
    <router-link v-if="to" class="link" :to="to" :title="title">
      <span class="content">
        <slot></slot>
      </span>
    </router-link>
    <span v-else class="content">
      <slot></slot>
    </span>
  </component>
</template>

<script>
export default {
  name: 'AppButton',
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: '',
    },
    to: {
      type: String || Object,
      default: '',
    },
    disableAos: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    className() {
      return `--${this.variant} ${this.size ? `--${this.size}` : ``} ${this.to ? '' : '--no-link'}`
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/components/common/AppButton';
</style>
