export default function ({ store, route, redirect }) {
  const availableLocales = store.state.enabledLocales

  const currentLocale = route.params.locale || store.state.language

  if (!availableLocales.includes(currentLocale)) {
    let redirectPath = route.meta[0].redirectPath
    if (redirectPath === 'case-study') {
      redirectPath = `${redirectPath}/${route.params.slug}`
    }
    redirect(`/${redirectPath}` ?? '/')
  }
}
