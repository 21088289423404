import 'vanilla-cookieconsent/dist/cookieconsent.css'
import 'vanilla-cookieconsent/src/cookieconsent.js'

const getCookieValue = (cookieName) => {
  const name = `${cookieName}=`
  const cookies = document.cookie.split(';').map((cookie) => cookie.trim())

  for (const cookie of cookies) {
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length)
    }
  }

  return null
}

const removeAllCookiesExcept = (keepCookies) => {
  // Get all cookies for the current domain
  const cookies = document.cookie.split(';')

  // Iterate through the cookies and delete each one, except those in keepCookies array
  for (const cookie of cookies) {
    const trimmedCookie = cookie.trim()

    // Check if the cookie is NOT in the keepCookies array
    if (!keepCookies.some((keepCookie) => trimmedCookie.startsWith(keepCookie))) {
      // Not in the keepCookies array, so delete the cookie
      document.cookie = `${trimmedCookie}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=${window.location.hostname}`
    }
  }
}

let gaLoaded = false

const cookieConsentPlugin = () => {
  const cookieConsent = window.initCookieConsent()
  cookieConsent.run({
    current_lang: document.documentElement.lang.split('-')[0],
    autoclear_cookies: !1,
    page_scripts: 1,
    onAccept(cookie) {
      handleAnalyticsScripts(cookie.categories)
    },
    onChange(cookie) {
      handleAnalyticsScripts(cookie.categories)
    },

    languages: {
      en: {
        consent_modal: {
          description: `Our Website uses cookies in order for the website to function properly. We may also use cookies for analytical and marketing purposes, in particular to tailor advertising content to your preferences. By clicking on the "Accept all" button, you consent to the storage of cookies on your device for the purposes of improving your use of the site navigation, analysing site usage, and supporting our marketing activities. If you would like to customise your preferences, go to <button type="button" data-cc="c-settings" class="cc-link">Cookies Settings</button>. For more information, please visit our <a href="privacy-policy" class="cc-link">Privacy Policy</a> page.`,
          primary_btn: {
            text: 'Accept all',
            role: 'accept_all',
          },
          secondary_btn: {
            text: 'Settings',
            role: 'settings',
          },
        },
        settings_modal: {
          title: 'Managing cookie preferences',
          save_settings_btn: 'Save',
          accept_all_btn: 'Accept all',
          reject_all_btn: 'Reject All',
          close_btn_label: 'Close',
          categories: {
            analytics: {
              cookies: ['_g'], // Specify the cookies related to Google Analytics
              label: 'Analytics',
              description: 'Collects anonymous data for website analytics.',
            },
          },
          cookie_table_headers: [
            {
              col1: 'Name',
            },
            {
              col2: 'Domain',
            },
            {
              col3: 'Expiration',
            },
            {
              col4: 'Description',
            },
          ],
          blocks: [
            {
              description:
                'Decide which cookies you want to enable. You can change your settings at any time. Please note that restricting cookies may block the use of certain features. For information on how to delete cookies, please use the help function in your browser.<br><br>Using the sliders, the different types of cookies can be switched on and off:',
            },
            {
              title: 'Analytical cookies',
              description:
                'Analytical cookies allow us to obtain information such as the number of visits and sources of traffic to the Website. They are used to determine which pages are more and which are less popular, and to understand how Users navigate the Website by keeping statistics on traffic on the Website. The processing is done to improve the performance of the Website. The information collected by these cookies is aggregated and is therefore not intended to establish the identity of the User. Analytical cookies may be set by us or by external providers (Google Analytics) whose services have been added to our websites.<br><br><strong style="font-weight: bold;">By agreeing to the use of cookies for analytical purposes, you must be aware that information about your browsing sessions (e.g. time spent on visited pages) and Google Analytics service identifiers will be transmitted to Google servers located in the USA. Due to Google\'s failure to implement appropriate safeguards, the aforementioned data may be made available to US services at their request.</strong>',
              toggle: {
                value: 'analytics',
                enabled: !0,
              },
            },
          ],
        },
      },
    },
  })
}

window.addEventListener('DOMContentLoaded', () => {
  const constentExist = getCookieValue('cc_cookie')
  if (constentExist) {
    const constent = JSON.parse(constentExist)
    handleAnalyticsScripts(constent.categories)
  } else {
    removeAllCookiesExcept(['cc_cookie'])
  }
})

export default cookieConsentPlugin

function handleAnalyticsScripts(allowed) {
  if (allowed.includes('analytics')) {
    // Load analytics scripts when consent is given
    loadAnalyticsScripts()
  } else {
    removeAllCookiesExcept(['cc_cookie'])
  }
}

function loadAnalyticsScripts() {
  if (gaLoaded) return
  gaLoaded = true
  setTimeout(() => {
    const gaScript = document.createElement('script')
    gaScript.text = `;(function (w, d, s, l, i) {
      w[l] = w[l] || []
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
      const f = d.getElementsByTagName(s)[0]
      const j = d.createElement(s)
      const dl = l != 'dataLayer' ? '&l=' + l : '' // eslint-disable-line eqeqeq
      j.async = true
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
      f.parentNode.insertBefore(j, f)
    })(window, document, 'script', 'dataLayer', 'GTM-N3363N2Q')`
    document.head.appendChild(gaScript)
  }, 3000)
}
