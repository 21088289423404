<template>
  <transition name="page-transition">
    <div v-show="loading" class="loading-container" :class="{ '--over-nav': overNav }">
      <AppLoader />
    </div>
  </transition>
</template>

<script>
import AppLoader from '@/components/common/AppLoader'
export default {
  name: 'AppPageLoader',
  components: {
    AppLoader,
  },
  data() {
    return {
      loading: true,
      overNav: true,
    }
  },
  mounted() {
    this.overNav = false
  },
  methods: {
    start() {
      this.loading = true
    },
    finish() {
      this.$nextTick(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/layout/AppPageLoader';
</style>
