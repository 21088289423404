import { gql } from 'graphql-tag'

export const GlobalSectionsQueryEN = gql`
  query {
    globalSections {
      globalSections {
        general {
          navigation {
            aboutUs {
              en
            }
            salesApp3d {
              en
            }
            webSalesSolution {
              en
            }
            caseStudies {
              en
            }
            contact {
              en
            }
          }
          requestADemo {
            en
          }
        }
        clients {
          ... on Client {
            id
            clients {
              client {
                logo {
                  mediaItemUrl
                }
                name
                url
              }
            }
          }
        }
        languages {
          english
          polish
          french
          german
          spanish
        }
        en {
          caseStudies {
            title
            subtitle
            acfButtonContent
            caseStudyItem {
              caseStudy {
                ... on CaseStudy {
                  id
                  slug
                  caseStudyItem {
                    en {
                      caseStudiesBoxSubtitle
                      description
                      pageSubtitle
                      fieldGroupName
                    }
                    general {
                      caseStudiesBoxImage {
                        srcSet(size: _1024X1024)
                        sizes(size: _1024X1024)
                        imageBlur(size: "full")
                        mediaItemUrl
                      }
                      caseStudiesBoxVideo {
                        mediaItemUrl
                      }
                      caseStudiesPageImage {
                        srcSet(size: LARGE)
                        imageBlur(size: "full")
                        mediaItemUrl
                      }
                      caseStudiesPageVideo {
                        mediaItemUrl
                      }
                      clientName
                      video {
                        mediaItemUrl
                      }
                    }
                  }
                }
              }
            }
          }
          ourCustomers {
            title
            subtitle
          }
          webSolutions {
            title
            subtitle
            buttonContent
            webSolutionItem {
              title
              icon
              content
              appUrl
            }
          }
          mapSection {
            title
            subtitle
            buttonContent
            acfCounters {
              counterItem {
                value
                subvalue
                largeSubvalue
                title
                icon {
                  mediaItemUrl
                }
              }
            }
          }
          acfMeeting {
            title
            subtitle
          }
          privacyPolicyAndCookies {
            cookiesBannerContent
            privacyPolicyPageContent
          }
        }
      }
    }
  }
`

export const GlobalSectionsQueryFR = gql`
  query {
    globalSections {
      globalSections {
        general {
          navigation {
            aboutUs {
              fr
            }
            salesApp3d {
              fr
            }
            webSalesSolution {
              fr
            }
            caseStudies {
              fr
            }
            contact {
              fr
            }
          }
          requestADemo {
            fr
          }
        }
        clients {
          ... on Client {
            id
            clients {
              client {
                logo {
                  mediaItemUrl
                }
                name
                url
              }
            }
          }
        }
        languages {
          english
          polish
          french
          german
          spanish
        }
        fr {
          caseStudies {
            title
            subtitle
            acfButtonContent
            caseStudyItem {
              caseStudy {
                ... on CaseStudy {
                  id
                  slug
                  caseStudyItem {
                    fr {
                      caseStudiesBoxSubtitle
                      description
                      pageSubtitle
                      fieldGroupName
                    }
                    general {
                      caseStudiesBoxImage {
                        srcSet(size: _1024X1024)
                        sizes(size: _1024X1024)
                        imageBlur(size: "full")
                        mediaItemUrl
                      }
                      caseStudiesBoxVideo {
                        mediaItemUrl
                      }
                      caseStudiesPageImage {
                        srcSet(size: LARGE)
                        imageBlur(size: "full")
                        mediaItemUrl
                      }
                      caseStudiesPageVideo {
                        mediaItemUrl
                      }
                      clientName
                      video {
                        mediaItemUrl
                      }
                    }
                  }
                }
              }
            }
          }
          ourCustomers {
            title
            subtitle
          }
          webSolutions {
            title
            subtitle
            buttonContent
            webSolutionItem {
              title
              icon
              content
              appUrl
            }
          }
          mapSection {
            title
            subtitle
            buttonContent
            acfCounters {
              counterItem {
                value
                subvalue
                largeSubvalue
                title
                icon {
                  mediaItemUrl
                }
              }
            }
          }
          acfMeeting {
            title
            subtitle
          }
          privacyPolicyAndCookies {
            cookiesBannerContent
            privacyPolicyPageContent
          }
        }
      }
    }
  }
`

export const GlobalSectionsQueryDE = gql`
  query {
    globalSections {
      globalSections {
        general {
          navigation {
            aboutUs {
              de
            }
            salesApp3d {
              de
            }
            webSalesSolution {
              de
            }
            caseStudies {
              de
            }
            contact {
              de
            }
          }
          requestADemo {
            de
          }
        }
        clients {
          ... on Client {
            id
            clients {
              client {
                logo {
                  mediaItemUrl
                }
                name
                url
              }
            }
          }
        }
        languages {
          english
          polish
          french
          german
          spanish
        }
        de {
          caseStudies {
            title
            subtitle
            acfButtonContent
            caseStudyItem {
              caseStudy {
                ... on CaseStudy {
                  id
                  slug
                  caseStudyItem {
                    de {
                      caseStudiesBoxSubtitle
                      description
                      pageSubtitle
                      fieldGroupName
                    }
                    general {
                      caseStudiesBoxImage {
                        srcSet(size: _1024X1024)
                        sizes(size: _1024X1024)
                        imageBlur(size: "full")
                        mediaItemUrl
                      }
                      caseStudiesBoxVideo {
                        mediaItemUrl
                      }
                      caseStudiesPageImage {
                        srcSet(size: LARGE)
                        imageBlur(size: "full")
                        mediaItemUrl
                      }
                      caseStudiesPageVideo {
                        mediaItemUrl
                      }
                      clientName
                      video {
                        mediaItemUrl
                      }
                    }
                  }
                }
              }
            }
          }
          ourCustomers {
            title
            subtitle
          }
          webSolutions {
            title
            subtitle
            buttonContent
            webSolutionItem {
              title
              icon
              content
              appUrl
            }
          }
          mapSection {
            title
            subtitle
            buttonContent
            acfCounters {
              counterItem {
                value
                subvalue
                largeSubvalue
                title
                icon {
                  mediaItemUrl
                }
              }
            }
          }
          acfMeeting {
            title
            subtitle
          }
          privacyPolicyAndCookies {
            cookiesBannerContent
            privacyPolicyPageContent
          }
        }
      }
    }
  }
`

export const GlobalSectionsQueryES = gql`
  query {
    globalSections {
      globalSections {
        general {
          navigation {
            aboutUs {
              es
            }
            salesApp3d {
              es
            }
            webSalesSolution {
              es
            }
            caseStudies {
              es
            }
            contact {
              es
            }
          }
          requestADemo {
            es
          }
        }
        languages {
          english
          polish
          french
          german
          spanish
        }
        clients {
          ... on Client {
            id
            clients {
              client {
                logo {
                  mediaItemUrl
                }
                name
                url
              }
            }
          }
        }
        es {
          caseStudies {
            title
            subtitle
            acfButtonContent
            caseStudyItem {
              caseStudy {
                ... on CaseStudy {
                  id
                  slug
                  caseStudyItem {
                    es {
                      caseStudiesBoxSubtitle
                      description
                      pageSubtitle
                      fieldGroupName
                    }
                    general {
                      caseStudiesBoxImage {
                        srcSet(size: _1024X1024)
                        sizes(size: _1024X1024)
                        imageBlur(size: "full")
                        mediaItemUrl
                      }
                      caseStudiesBoxVideo {
                        mediaItemUrl
                      }
                      caseStudiesPageImage {
                        srcSet(size: LARGE)
                        imageBlur(size: "full")
                        mediaItemUrl
                      }
                      caseStudiesPageVideo {
                        mediaItemUrl
                      }
                      clientName
                      video {
                        mediaItemUrl
                      }
                    }
                  }
                }
              }
            }
          }
          ourCustomers {
            title
            subtitle
          }
          webSolutions {
            title
            subtitle
            buttonContent
            webSolutionItem {
              title
              icon
              content
              appUrl
            }
          }
          mapSection {
            title
            subtitle
            buttonContent
            acfCounters {
              counterItem {
                value
                subvalue
                largeSubvalue
                title
                icon {
                  mediaItemUrl
                }
              }
            }
          }
          acfMeeting {
            title
            subtitle
          }
          privacyPolicyAndCookies {
            cookiesBannerContent
            privacyPolicyPageContent
          }
        }
      }
    }
  }
`

export const GlobalSectionsQueryPL = gql`
  query {
    globalSections {
      globalSections {
        general {
          navigation {
            aboutUs {
              pl
            }
            salesApp3d {
              pl
            }
            webSalesSolution {
              pl
            }
            caseStudies {
              pl
            }
            contact {
              pl
            }
          }
          requestADemo {
            pl
          }
        }
        clients {
          ... on Client {
            id
            clients {
              client {
                logo {
                  mediaItemUrl
                }
                name
                url
              }
            }
          }
        }
        languages {
          english
          polish
          french
          german
          spanish
        }
        pl {
          caseStudies {
            title
            subtitle
            acfButtonContent
            caseStudyItem {
              caseStudy {
                ... on CaseStudy {
                  id
                  slug
                  caseStudyItem {
                    en {
                      caseStudiesBoxSubtitle
                      description
                      pageSubtitle
                      fieldGroupName
                    }
                    general {
                      caseStudiesBoxImage {
                        srcSet(size: _1024X1024)
                        sizes(size: _1024X1024)
                        imageBlur(size: "full")
                        mediaItemUrl
                      }
                      caseStudiesBoxVideo {
                        mediaItemUrl
                      }
                      caseStudiesPageImage {
                        srcSet(size: LARGE)
                        imageBlur(size: "full")
                        mediaItemUrl
                      }
                      caseStudiesPageVideo {
                        mediaItemUrl
                      }
                      clientName
                      video {
                        mediaItemUrl
                      }
                    }
                  }
                }
              }
            }
          }
          ourCustomers {
            title
            subtitle
          }
          webSolutions {
            title
            subtitle
            buttonContent
            webSolutionItem {
              title
              icon
              content
              appUrl
            }
          }
          mapSection {
            title
            subtitle
            buttonContent
            acfCounters {
              counterItem {
                value
                subvalue
                largeSubvalue
                title
                icon {
                  mediaItemUrl
                }
              }
            }
          }
          acfMeeting {
            title
            subtitle
          }
          privacyPolicyAndCookies {
            cookiesBannerContent
            privacyPolicyPageContent
          }
        }
      }
    }
  }
`
