<template>
  <div id="loading" class="loading"></div>
</template>

<script>
export default {
  props: {
    small: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/components/common/AppLoader';
</style>
