import { gql } from 'graphql-tag'

export const GlobalContactQueryEN = gql`
  query {
    contactOptions {
      contactOptions {
        linkedinUrl
        ytUrl
        contactTitle {
          en
        }
        meetingModalTitle {
          en
        }
        meetingModalSubtitle {
          en
        }
        ceoBox {
          email
          phone
          image {
            mediaItemUrl
            imageBlur(size: "full")
          }
        }
        form {
          buttonContent {
            en
          }
          email {
            en
          }
          message {
            en
          }
          name {
            en
          }
          phone {
            en
          }
          company {
            en
          }
          subjects {
            subject {
              en
            }
          }
        }
        offices {
          office {
            address {
              en
            }
            email
            phone
            location {
              en
            }
            flagIcon {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
`

export const GlobalContactQueryFR = gql`
  query {
    contactOptions {
      contactOptions {
        linkedinUrl
        ytUrl
        contactTitle {
          fr
        }
        meetingModalTitle {
          fr
        }
        meetingModalSubtitle {
          fr
        }
        ceoBox {
          email
          phone
          image {
            mediaItemUrl
            imageBlur(size: "full")
          }
        }
        form {
          buttonContent {
            fr
          }
          email {
            fr
          }
          message {
            fr
          }
          name {
            fr
          }
          phone {
            fr
          }
          company {
            fr
          }
          subjects {
            subject {
              fr
            }
          }
        }
        offices {
          office {
            address {
              fr
            }
            email
            phone
            location {
              fr
            }
            flagIcon {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
`

export const GlobalContactQueryDE = gql`
  query {
    contactOptions {
      contactOptions {
        linkedinUrl
        ytUrl
        contactTitle {
          de
        }
        meetingModalTitle {
          de
        }
        meetingModalSubtitle {
          de
        }
        ceoBox {
          email
          phone
          image {
            mediaItemUrl
            imageBlur(size: "full")
          }
        }
        form {
          buttonContent {
            de
          }
          email {
            de
          }
          message {
            de
          }
          name {
            de
          }
          phone {
            de
          }
          company {
            de
          }
          subjects {
            subject {
              de
            }
          }
        }
        offices {
          office {
            address {
              de
            }
            email
            phone
            location {
              de
            }
            flagIcon {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
`

export const GlobalContactQueryES = gql`
  query {
    contactOptions {
      contactOptions {
        linkedinUrl
        ytUrl
        contactTitle {
          es
        }
        meetingModalTitle {
          es
        }
        meetingModalSubtitle {
          es
        }
        ceoBox {
          email
          phone
          image {
            mediaItemUrl
            imageBlur(size: "full")
          }
        }
        form {
          buttonContent {
            es
          }
          email {
            es
          }
          message {
            es
          }
          name {
            es
          }
          phone {
            es
          }
          company {
            es
          }
          subjects {
            subject {
              es
            }
          }
        }
        offices {
          office {
            address {
              es
            }
            email
            phone
            location {
              es
            }
            flagIcon {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
`

export const GlobalContactQueryPL = gql`
  query {
    contactOptions {
      contactOptions {
        linkedinUrl
        ytUrl
        contactTitle {
          pl
        }
        meetingModalTitle {
          pl
        }
        meetingModalSubtitle {
          pl
        }
        ceoBox {
          email
          phone
          image {
            mediaItemUrl
            imageBlur(size: "full")
          }
        }
        form {
          buttonContent {
            pl
          }
          email {
            pl
          }
          message {
            pl
          }
          name {
            pl
          }
          phone {
            pl
          }
          company {
            pl
          }
          subjects {
            subject {
              pl
            }
          }
        }
        offices {
          office {
            address {
              pl
            }
            email
            phone
            location {
              pl
            }
            flagIcon {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
`
